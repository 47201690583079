import "../scss/index.scss";

document.addEventListener("DOMContentLoaded", function () {
  AOS.init({
    duration: 500,
  });
  // fix: some anims where not triggered until called AOS.refresh()
  setTimeout(() => {
    AOS.refresh();
  }, 500);
  // Change menu color on scroll
  let last_known_scroll_position = 0;
  let ticking = false;
  let isUpdated = false;
  const headerEl = document.getElementsByTagName("header");
  updateMenuStateOnScrollPos(window.scrollY, isUpdated);
  window.addEventListener("scroll", function (e) {
    last_known_scroll_position = window.scrollY;
    if (!ticking) {
      window.requestAnimationFrame(function () {
        updateMenuStateOnScrollPos(last_known_scroll_position, isUpdated);
        ticking = false;
      });
      ticking = true;
    }
  });
  // ---------
  // Check scroll position for menu
  function updateMenuStateOnScrollPos(scroll_pos, isUpdated) {
    if (!isUpdated) {
      if (scroll_pos >= 0 && scroll_pos < 30) {
        isUpdated = true;
        headerEl[0].classList.remove("scrolled");
        headerEl[0].classList.add("not-scrolled");
      } else {
        headerEl[0].classList.remove("not-scrolled");
        headerEl[0].classList.add("scrolled");
        isUpdated = false;
      }
    }
  }
  // ---------
  // Handle display of fixed footer banner
  let bannerLocalSValue = localStorage.getItem(
    "sathyamproject.org-banner-display"
  );
  let fixedFooter = document.getElementById("fixed-footer");
  let campaignDateEl = document.getElementById("fixed-footer-campaign-date");
  let campaignDate = new Date(campaignDateEl.innerHTML.replace(/\s/g, ""));
  // If value in local storage
  if (bannerLocalSValue !== null) {
    let lastLSDate = new Date(bannerLocalSValue);
    checkBannerDisplay(lastLSDate, campaignDate);
  } else {
    fixedFooter.style.display = "flex";
  }
  let closeCross = fixedFooter.getElementsByTagName("svg");
  closeCross[0].addEventListener(
    "click",
    () => {
      localStorage.setItem("sathyamproject.org-banner-display", campaignDate);
      fixedFooter.style.display = "none";
    },
    false
  );
  function checkBannerDisplay(lastLocalDate, campaignDate) {
    if (dateFormated(campaignDate) !== dateFormated(lastLocalDate)) {
      fixedFooter.style.display = "flex";
    }
  }
  function dateFormated(date) {
    return date.getDate() + "/" + date.getMonth() + "/" + date.getFullYear();
  }

  // -----------
  // Copy text to clipboard in /get-involved
  if (window.location.pathname === "/get-involved") {
    const shareButton = document.querySelector(".share-btn");
    const shareButtonTooltip = document.querySelector(".share-btn-tooltip");

    shareButton.onclick = function () {
      document.execCommand("copy");
    };

    shareButton.addEventListener("copy", function (event) {
      event.preventDefault();
      if (event.clipboardData) {
        event.clipboardData.setData("text/plain", window.location.origin);
        // console.log(event.clipboardData.getData("text"));
        shareButtonTooltip.classList.add("visible");
        setTimeout(() => {
          shareButtonTooltip.classList.remove("visible");
        }, 2000);
      }
    });
  }
});
